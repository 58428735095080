<template>
  <v-container
    class="card pa-0"
    fluid
  >
    <v-card
      class="card-body ma-0 pa-0"
      elevation="0"
    >
      <v-card-title
        class="card-header card-header-tab bg-white border-0 transparent"
      >
        <div
          :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
          class="card-title text-capitalize pt-3"
        >
          {{ $t('eventbee.lang_refundPolicies') }}
        </div>

        <div class="btn-actions-pane-right actions-icon-btn">
          <v-btn
            @click="dialog = true"
            color="primary"
            icon
          >
            <v-icon>add</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider class="pa-0 ma-0" />
      <v-card-text class="pa-0 ma-0">
        <Datatable
          :api-endpoint="ENDPOINTS.DATATABLES.EVENTBEE.REFUNDPOLICIES"
          :datatable-headers="datatableHeaders"
          :permissionDelete="true"
          :permissionEdit="true"
          @deleteEntries="deleteEntries"
          @deleteEntry="deleteEntry"
          @editEntry="editEntry"
          no-excel-export
          ref="segments"
          show-delete-buttons
          show-edit-buttons
          show-select-checkbox
        ></Datatable>
      </v-card-text>
    </v-card>

    <v-dialog
      max-width="100%"
      width="700"
      persistent
      v-model="dialog"
    >
      <div
        :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''"
        class="card"
      >
        <div
          :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          class="card-header pt-3"
        >
          <div
            :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
            class="card-title"
          >
            {{ this.formTitle }}
          </div>
        </div>

        <div class="card-body">
          <v-form
            lazy-validation
            v-model="valid"
            ref="policyForm"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  outlined
                  dense
                  :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                  :label="$t('generic.lang_name')"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  @focus="showTouchKeyboard"
                >
                </v-text-field>
              </v-col>
              <v-col
                class=""
                cols="12"
              >
                <v-switch
                  v-model="form.ticket_refundable"
                  class="ma-0"
                  :true-value="true"
                  :false-value="false"
                  :label="$t('eventbee.lang_ticket_refundable')"
                  dense
                  outlined
                ></v-switch>
              </v-col>

              <v-col
                cols="12"
                v-if="form.ticket_refundable"
              >
                <v-text-field
                  v-model="form.ticket_refundable_till"
                  dense
                  :label="$t('eventbee.lang_refundable_before')"
                  :rules="[(v) => (form.ticket_refundable ? !!v || $t('generic.lang_requiredField') : true)]"
                  outlined
                  :min="0"
                  type="number"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  @focus="showTouchKeyboard"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="pr-0"
                v-if="form.ticket_refundable"
              >
                <v-text-field
                  v-model="form.ticket_refund_deduct_price"
                  dense
                  :label="$t('eventbee.lang_fixed_price_to_refund')"
                  outlined
                  @blur="touchForm"
                  :rules="[
                    (v) => !!v || !!form.ticket_refund_deduct_percentage,
                  ]"
                  :min="0"
                  type="number"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  @focus="showTouchKeyboard"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
                v-if="form.ticket_refundable"
              >
                <v-text-field
                  v-model="form.ticket_refund_deduct_percentage"
                  dense
                  :label="$t('eventbee.lang_ticket_refund_deduct_percentage')"
                  outlined
                  @blur="touchForm"
                  :min="0"
                  :rules="[(v) => !!v || !!form.ticket_refund_deduct_price]"
                  type="number"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  @focus="showTouchKeyboard"
                >
                  <template v-slot:prepend><div class="mt-1">{{ $t('generic.lang_gen_or') }}</div></template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-subheader>
                  <strong>{{ $t('generic.lang_text') }}</strong>
                </v-subheader>
                <quill-editor
                  id="editor1"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :options="quillOptions"
                  @focus="showTouchKeyboard"
                  class="mb-12"
                  output="html"
                  v-model="form.text"
                >
                </quill-editor>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div
          :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          class="card-footer text-right"
        >
          <v-spacer />
          <v-btn
            @click="dialog = false"
            class="btn btn-danger bg-danger text-light mx-auto"
          >
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>
          <v-btn
            :disabled="!valid || loading"
            :loading="loading"
            @click="save"
            class="btn bg-primary text-light btn-primary mx-auto ml-2"
          >
            {{
              this.edit
                ? this.$t('generic.lang_save')
                : this.$t('generic.lang_create')
            }}
          </v-btn>
        </div>
      </div>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard
          v-if="touchKeyboard.visible"
          id="onScreenKeyboard"
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
        />
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import Datatable from '@/components/datatable/Datatable';
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import mixin from '@/mixins/KeyboardMixIns';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'RefundPoliciesComponent',
  components: {
    Datatable,
    quillEditor,
  },
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      dialog: false,
      valid: false,
      form: {
        id: 0,
        name: null,
        text: null,
        ticket_refund_deduct_percentage: 0,
        ticket_refund_deduct_price: 0,
        ticket_refundable_till: null,
        ticket_refundable: false,
      },
      defaultItem: {
        id: 0,
        name: null,
        text: null,
      },
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            [{ font: [] }],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
          ],
        },
      },
      loading: false,
      edit: false,
    };
  },
  watch: {
    'dialog'(val) {
      val || val & this.close();
    },
    'form.ticket_refund_deduct_price': {
      handler: function (val) {
        if (val > 0) {
          this.form.ticket_refund_deduct_percentage = 0;
        }
      },
      deep: true,
    },
    'form.ticket_refund_deduct_percentage': {
      handler: function (val) {
        if (val > 0) {
          this.form.ticket_refund_deduct_price = 0;
        }
      },
      deep: true,
    },
  },
  methods: {
    touchForm() {
      if (this.$refs.policyForm) this.$refs.policyForm.resetValidation();
    },
    deleteData(idsToDelete = []) {
      let self = this;
      this.$swal({
        title:
          this.$t('generic.lang_delete') +
          ' ' +
          this.$t('eventbee.lang_refundPolicies'),
        text:
          this.$t('generic.lang_deleteConfirmationMSG') +
          ' ' +
          this.$t('eventbee.lang_refundPolicies'),
        icon: 'warning',
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
          }
          this.axios
            .post(ENDPOINTS.EVENTBEE.SETTINGS.REFUNDPOLICIES.DELETE, {
              id: idsToDelete,
            })
            .then((res) => {
              if (res.status === 200) {
                Events.$emit('showSnackbar', {
                  message: this.$t('generic.lang_success'),
                  color: 'success',
                });
                self.$refs.segments.getDataFromApi();
                self.$refs.segments.resetSelectedRows();
              } else {
                Events.$emit('showSnackbar', {
                  message: this.$t('generic.lang_errorOccurred'),
                  color: 'error',
                });
              }
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteEntry(entry) {
      this.deleteData([entry.id]);
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
    save() {
      if (!this.$refs.policyForm.validate()) {
        return;
      }
      if (this.edit) {
        this.loading = true;
        this.axios
          .post(ENDPOINTS.EVENTBEE.SETTINGS.REFUNDPOLICIES.UPDATE, this.form)
          .then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_success'),
                color: 'success',
              });
              this.close();
              this.$refs.segments.getDataFromApi();
              this.$refs.segments.resetSelectedRows();
            } else if (res.data.status === 'FAILED') {
              Events.$emit('showSnackbar', {
                message: res.data.msg,
                color: 'error',
              });
            } else {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: err.message,
              color: 'error',
            });
          })
          .finally(() => {
            this.loading = false;
            this.dialog = false;
          });
      } else {
        this.loading = true;
        this.axios
          .post(ENDPOINTS.EVENTBEE.SETTINGS.REFUNDPOLICIES.CREATE, this.form)
          .then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_success'),
                color: 'success',
              });
              this.close();
              this.$refs.segments.getDataFromApi();
              this.$refs.segments.resetSelectedRows();
            } else if (res.data.status === 'FAILED') {
              Events.$emit('showSnackbar', {
                message: res.data.msg,
                color: 'error',
              });
            } else {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: err.message,
              color: 'error',
            });
          })
          .finally(() => {
            this.loading = false;
            this.form = Object.assign({}, this.defaultItem);
          });
      }
    },
    editEntry(entry) {
      this.edit = true;
      this.dialog = true;
      this.form = Object.assign({}, entry);
    },
    close() {
      this.edit = false;
      this.dialog = false;
      this.form = Object.assign({}, this.defaultItem);
    },
  },
  computed: {
    formTitle: function () {
      return this.edit
        ? this.$t('generic.lang_edit') +
            ' ' +
            this.$t('eventbee.lang_refundPolicies')
        : this.$t('generic.lang_create') +
            ' ' +
            this.$t('eventbee.lang_refundPolicies');
    },
    datatableHeaders: function () {
      return [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: false,
        },
        {
          text: this.$t('generic.lang_name'),
          value: 'name',
          sort: 'asc',
        },
        {
          text: 'text',
          align: 'left',
          value: 'text',
          hide: true,
          width: 80,
        },
        {
          text: this.$t('eventbee.lang_ticket_refundable'),
          align: 'left',
          value: 'ticket_refundable',
          hide: true,
        },
        {
          text: this.$t('eventbee.lang_refundable_before'),
          align: 'left',
          value: 'ticket_refundable_till',
          hide: true,
        },
        {
          text: this.$t('eventbee.lang_fixed_price_to_refund'),
          align: 'left',
          value: 'ticket_refund_deduct_price',
          hide: true,
        },
        {
          text: this.$t('eventbee.lang_fixed_price_to_refund'),
          align: 'left',
          value: 'ticket_refund_deduct_percentage',
          hide: true,
        },
      ];
    },
  },
  mounted() {},
};
</script>

<style scoped>
#editor1 {
  height: 230px;
  width: 100%;
}
</style>
